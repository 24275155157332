<template>
  <div class="promote" v-if="promoteData">
    <slot name="title"></slot>
    <img src="~image/user/user/gold.png" class="promote-icon_gold" />
    <div class="promote-datas">
      <div class="promote-data">
        <app-icon class="data-icon" name="user-dingdan" size="59"></app-icon>
        <div class="data-content">
          <p class="data-name">订单(笔)</p>
          <p class="data-text">{{ promoteData.orderTotal }}</p>
        </div>
      </div>
      <div class="promote-data">
        <app-icon class="data-icon" name="user-xiaoshoue" size="59"></app-icon>
        <div class="data-content">
          <p class="data-name">销售额(元)</p>
          <p class="data-text">{{ promoteData.saleVolume }}</p>
        </div>
      </div>
      <div class="promote-data">
        <app-icon class="data-icon" name="user-yongjin" size="59"></app-icon>
        <div class="data-content">
          <p class="data-name">累计佣金(元)</p>
          <p class="data-text">{{ promoteData.commission }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  computed: {
    ...mapState({ promoteData: state => state.user.promoteData })
  }
};
</script>

<style lang="scss" scoped>
.promote {
  padding: 40px 30px 51px;
  border-radius: 16px;
  background: #fff;
  box-shadow: 0px 0px 28px 0px rgba(153, 153, 153, 0.22);

  &-icon_gold {
    position: absolute;
    top: -23px;
    right: -20px;
    width: 76px;
    height: 82px;
  }

  &-datas {
    @include flex-wrap;
    @include justify-between;
    margin-top: 55px;
  }

  &-data {
    display: flex;
    padding: 45px 27px 0;
    width: 198px;
    height: 152px;
    border-radius: 16px;

    &:nth-of-type(1) {
      background: rgba(255, 134, 1, 0.05);
    }

    &:nth-of-type(2) {
      background: rgba(12, 154, 255, 0.05);
    }

    &:nth-of-type(3) {
      margin-bottom: 0;
      background: rgba(173, 102, 255, 0.05);
    }

    .data {
      &-icon {
        position: absolute;
        top: -25px;
        left: 17px;
        margin-right: 28px;
      }

      &-name {
        margin-bottom: 10px;
        color: #999;
      }

      &-text {
        font-size: 28px;
        font-weight: 500;
      }
    }
  }
}
</style>
