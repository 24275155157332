<template>
  <div class="module-userInfo">
    <div class="user-info_wrap">
      <img v-if="userInfo.face" :src="userInfo.face" class="user-face" />
      <app-icon class="user-face" name="touxiang" size="120" v-else></app-icon>
      <div class="user-info">
        <template v-if="userInfo.id">
          <p class="user-name">
            <span>{{ userInfo.name }}</span>
            <img
              class="name-icon"
              v-if="userInfo.serviceTime"
              src="~image/user/user/vip.png"
              alt=""
            />
            <router-link to="/user/servicePage">
              <img
                class="name-icon_superVip"
                v-if="superVipData"
                src="~image/user/user/superVip.png"
                alt=""
            /></router-link>
          </p>
          <p class="user-phone">{{ userInfo.phone }}</p>
        </template>
        <router-link v-else class="user-login" to="/login">请登录</router-link>
      </div>
      <router-link
        v-if="userInfo.id"
        class="user-service"
        to="/user/serviceOrderList"
        >我的服务</router-link
      >
      <!-- TODO 编辑 -->
      <!-- <router-link v-if="userInfo.id" class="user-editBtn" to="/user/userEdit"
        ><app-icon class="user-editBtn_icon" name="bangzhu" size="22"></app-icon
        >编辑</router-link
      > -->
    </div>
    <div class="user-vip">
      <template v-if="userInfo.serviceOrderNum">
        <p class="vip-info">{{ serviceTimeText }}</p>
        <router-link class="vip-link" to="/user/serviceList"
          >立即续费</router-link
        >
      </template>
    </div>
    <div class="user-datas">
      <div class="user-data">
        <p class="user-data_name">余额</p>
        <p class="user-data_text" v-if="userInfo.money">
          {{ userInfo.money }}
        </p>
        <p v-else class="user-data_none">-</p>
      </div>
      <!-- <div class="user-data">
        <p class="user-data_name">积分</p>
        <p class="user-data_text" v-if="userInfo.integral">
          {{ userInfo.integral }}
        </p>
        <p v-else class="user-data_none">-</p>
      </div> -->
      <router-link
        class="user-data"
        :to="
          userInfo.id && userInfo.orderNum
            ? `${$route.path}/orderList?userId=${userInfo.id}`
            : ''
        "
      >
        <p class="user-data_name">我的锦囊</p>
        <p class="user-data_text" v-if="userInfo.orderNum">
          {{ userInfo.orderNum }}<span class="small">期</span>
        </p>
        <p v-else class="user-data_none">-</p>
      </router-link>
      <router-link
        to="/distribution/withdrawal"
        class="user-data"
        v-if="userInfo.isSalesman"
      >
        <p class="user-data_name">可提现</p>
        <p class="user-data_text" v-if="userInfo.price">
          {{ userInfo.price }}
        </p>
        <p v-else class="user-data_none">-</p>
      </router-link>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  computed: {
    ...mapState({
      userInfo: state => state.user.userInfo,
      superVipData: state => state.user.superVipData
    }),

    serviceTimeText() {
      let { userInfo } = this;
      return userInfo.serviceTime
        ? `您的会员${
            userInfo.serviceTime === '0小时'
              ? '即将到期'
              : `还有 ${userInfo.serviceTime} 到期`
          }`
        : `您的会员已到期`;
    }
  }
};
</script>

<style lang="scss" scoped>
.module-userInfo {
  margin: 0 auto;
  padding: 60px 0 0;
  border-radius: 16px;
}

.user {
  &-info {
    &_wrap {
      @include align-center;
      width: 100%;
      margin-bottom: 29px;
    }
  }

  &-face {
    margin-right: 30px;
    width: 120px;
    height: 120px;
    border-radius: 50%;
  }

  &-name {
    @include align-center;
    margin-bottom: 12px;
    font-size: 32px;
    font-weight: 500;

    .name-icon {
      margin-left: 8px;
      width: 60px;
      height: 34px;

      &_superVip {
        @extend .name-icon;
        width: 58px;
        height: 40px;
      }
    }
  }

  &-phone {
    font-size: 24px;
  }

  &-login {
    width: 400px;
    font-size: 32px;
    font-weight: 500;
  }

  &-service {
    @include align-center;
    position: absolute;
    top: 12px;
    right: -30px;
    padding-left: 30px;
    width: 172px;
    height: 44px;
    border-radius: 100px 0px 0px 100px;
    font-size: 28px;
    color: #fff;
    background: #fb9938;

    &_icon {
      margin-right: 10px;
    }
  }

  &-vip {
    @include align-center;
    margin-bottom: 34px;
    min-height: 1px;
    font-size: 28px;
    color: #262626;

    .vip-link {
      @include flex-center-center;
      margin-top: -4px;
      margin-left: 32px;
      width: 180px;
      height: 58px;
      border-radius: 42px;
      font-size: 32px;
      font-weight: 500;
      color: #263b76;
      background: linear-gradient(90deg, #f7f9fc 0%, #cad3ee 100%);
    }
  }

  &-datas {
    @include align-center;
    padding: 30px 0;
    width: 100%;
    border-radius: 16px;
    background: #fff;
    box-shadow: 0px 0px 28px 0px rgba(153, 153, 153, 0.22);
  }

  &-data {
    @include align-center;
    flex-direction: column;
    flex: 1;

    &_name {
      margin-bottom: 10px;
      color: #666;
    }

    &_text {
      font-size: 40px;
      font-weight: 500;
      color: #f70e11;

      .small {
        font-size: 24px;
      }
    }

    &_none {
      @extend .user-data_text;
      color: #999;
    }
  }
}
</style>
