<template>
  <div class="module-masterList">
    <slot name="title"></slot>
    <div class="master-list">
      <router-link
        class="master-item"
        :to="`/lottery/master?masterId=${item.id}&forecastType=${item.type}`"
        v-for="item in list"
        :key="item.id"
      >
        <p class="item-new" v-if="item.new">新</p>
        <img class="item-face" :src="item.face" />
        <p class="item-name">{{ item.name }}</p>
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    list: {
      type: Array,
      default() {
        return [];
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.module-masterList {
  padding: 40px 30px;
  border-radius: 16px;
  background: #fff;
  box-shadow: 0px 0px 28px 0px rgba(153, 153, 153, 0.22);
}

.master {
  &-list {
    @include align-center;
    width: 100%;
  }

  &-item {
    @include flex-center-center;
    flex-direction: column;
    flex: 0 0 auto;
    margin: 50px 23px 0 0;
    width: 140px;

    &:last-of-type {
      margin-right: 0;
    }

    .item {
      &-new {
        @include flex-center-center;
        z-index: 1;
        position: absolute;
        top: -10px;
        right: 16px;
        width: 34px;
        height: 34px;
        border-radius: 50%;
        color: #fff;
        background: #ff5800;
      }

      &-face {
        margin-bottom: 8px;
        width: 88px;
        height: 88px;
        border-radius: 50%;
      }

      &-name {
        @include text-ellipsis;
        width: 100%;
        font-size: 28px;
        font-weight: 500;
        text-align: center;
      }
    }
  }
}
</style>
