<template>
  <div class="module-result">
    <router-link to="/lottery/history" :class="`result-info`">
      <div class="info-item">
        <p>
          <span class="info-value">{{ resultData.total }}</span
          >位
        </p>
        <p class="info-label">上期命中人数</p>
      </div>
      <div class="info-item">
        <p>
          <span class="info-value">{{ resultData.monthTotal }}</span
          >次
        </p>
        <p class="info-label">本月命中次数</p>
      </div>
      <div class="info-item">
        <p>
          <span class="info-value">{{ resultData.continuous }}</span
          >期
        </p>
        <p class="info-label">最高连续命中</p>
      </div>
    </router-link>
    <div class="result-container">
      <p class="result-title">
        <span>排列五</span>
        <span class="result-issue">第{{ resultData.issue }}期</span>
      </p>
      <div class="result-values">
        <p class="value" v-for="(val, idx) in resultData.value" :key="idx">
          <span class="value-text">
            {{ val }}
          </span>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
  data() {
    return {
      upadteLoading: false,
      curTime: 0,
      nextResultTime: 0
    };
  },
  computed: {
    ...mapState({
      resultData: state => state.result.resultData
    })
  },
  methods: {
    ...mapActions({ updateResultData: 'result/updateResultData' }),

    getResultDataReady() {
      let { curTime, nextResultTime } = this;
      let interval = nextResultTime - curTime;
      if (interval <= 0 || interval > 1800000) return;
      if (this.upadteTimer) clearTimeout(this.upadteTimer);
      this.curTime += 3000;
      this.upadteTimer = setTimeout(() => {
        return interval < 1200000
          ? this.getResultData()
          : this.getResultDataReady();
      }, 3000);
    },

    async getResultData() {
      if (this.upadteLoading) return;
      this.upadteLoading = true;
      let res = await this.updateResultData();
      if (!this.curTime) {
        this.curTime = res.time * 1000;
        this.nextResultTime = this.$dayjs(
          this.$dayjs(this.curTime).format('YYYY-M-D')
        )
          .add(20, 'h')
          .add(50, 'm')
          .valueOf();
      }
      // 如果已经执行clearResultDataUpdate，则不再轮询
      if (!this.upadteLoading) return;
      this.upadteLoading = false;
      this.getResultDataReady();
    },

    handleStickyChange(isFixed) {
      this.isFixed = isFixed;
    },

    clearResultDataUpdate() {
      if (this.upadteTimer) clearTimeout(this.upadteTimer);
      this.upadteLoading = false;
    }
  }
};
</script>

<style lang="scss" scoped>
.module-result {
  padding: 30px 0 0;
}

.result {
  &-info {
    @include align-center;
    z-index: 2;
    margin: 0 auto;
    width: 560px;
    height: 102px;
    border-radius: 24px;
    overflow: hidden;
    background: #fff7e7;

    .info {
      &-item {
        @include flex-center-center;
        flex-direction: column;
        width: 33.333%;
        height: 100%;
        font-size: 22px;
        color: #0e0e0e;

        &:not(:last-of-type)::after {
          content: '';
          display: block;
          position: absolute;
          right: 0;
          top: calc(50% - 30px);
          width: 2px;
          height: 60px;
          background: #ffd9ba;
        }
      }

      &-value {
        font-size: 36px;
        font-weight: 500;
      }

      &-label {
        margin-top: 2px;
        color: #f95151;
      }
    }
  }

  &-container {
    margin: -23px auto 0;
    padding: 46px 0 0;
    width: 690px;
    height: 196px;
    border-radius: 36px;
    background: linear-gradient(360deg, #ffae9b 0%, #ffe8c4 100%);
    box-shadow: 0px 10px 32px 0px rgba(105, 0, 0, 0.55);
  }

  &-title {
    margin-bottom: 16px;
    text-align: center;
    font-size: 32px;
    font-weight: 500;
    background: linear-gradient(180deg, #ff4f1f 0%, #cc0500 100%);
    background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  &-issue {
    margin-left: 20px;
  }

  &-values {
    @include flex-center-center;

    .value {
      @include flex-center-center;
      margin-right: 53px;
      width: 64px;
      height: 64px;
      border-radius: 50%;
      background: linear-gradient(360deg, #df0807 0%, #fa6127 100%);
      box-shadow: 0px 4px 20px 0px rgba(194, 12, 0, 0.4);
      &:last-of-type {
        margin-right: 0;
      }

      &-text {
        font-size: 42px;
        font-weight: 500;
        text-shadow: 0px 12px 20px #dc2200, 0px 2px 0px #ffffff;
        background: linear-gradient(180deg, #fffdf4 0%, #ffd2a1 100%);
        background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }
  }

  &-link {
    @include align-end;
    justify-content: center;
    margin: -134px auto 0;
    padding-bottom: 10px;
    width: 640px;
    height: 196px;
    border-radius: 106px;
    background: #ffede5;
    transition: margin 0.18s linear;

    &_fixed {
      @extend .result-link;
      display: none;
    }

    .link-text {
      @include flex-center-center;
      font-size: 28px;
      font-weight: 500;
      color: #e45338;
    }
  }
}
</style>
