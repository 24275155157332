<template>
  <div>
    <router-link class="ad-link" to="/user/serviceList"
      ><img class="ad-img" src="~image/lottery/master/masterList-ad.png"
    /></router-link>
    <div class="master-container">
      <div class="filter" v-show="!curSidebarIdx">
        <div
          :class="`filter-item${curFilter === 1 ? '_active' : ''}`"
          @click="handleFilterClick(1)"
        >
          <app-icon
            :class="`filter-icon${curFilter === 1 ? '_active' : ''}`"
            name="masterList-filter"
            size="32"
          ></app-icon>
          <span>命中率</span>
        </div>
        <div
          :class="`filter-item${curFilter === 2 ? '_active' : ''}`"
          @click="handleFilterClick(2)"
        >
          <app-icon
            :class="`filter-icon${curFilter === 2 ? '_active' : ''}`"
            name="masterList-filter"
            size="32"
          ></app-icon>
          <span>回报率</span>
        </div>
      </div>
      <van-sidebar
        v-model="curSidebarIdx"
        class="js-master-sidebar master-sidebar"
        @change="handleSidbarChange"
      >
        <van-sidebar-item
          class="js-sidebar-item"
          v-for="item in sidebarList"
          :key="item"
          :title="item | getForecastText"
        />
      </van-sidebar>
      <div
        :class="[
          'js-master-list',
          `master-list${!curSidebarIdx ? '_hasPaddingTop' : ''}`
        ]"
      >
        <router-link
          class="master-item"
          v-for="item in masterList"
          :key="item.id"
          :to="`/lottery/master?masterId=${item.id}&forecastType=${forecastType}`"
        >
          <master-item :masterData="item"></master-item>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import masterRankingImg1 from 'image/lottery/master/master-ranking_1.png';
import masterRankingImg2 from 'image/lottery/master/master-ranking_2.png';
import masterRankingImg3 from 'image/lottery/master/master-ranking_3.png';
import masterItem from './components/moduleMasterItem.vue';

export default {
  components: {
    masterItem
  },
  data() {
    return {
      curFilter: 1,
      masterRankingImg1,
      masterRankingImg2,
      masterRankingImg3,
      initialized: false,
      curSidebarIdx: 0,
      sidebarList: [
        10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 21, 22, 23, 24, 25, 26, 31, 1
      ],
      masterList: [],
      oldMasterList: []
    };
  },
  computed: {
    forecastType() {
      return this.sidebarList[this.curSidebarIdx];
    }
  },
  methods: {
    getMasterList(rankType = 'rank') {
      this.$http('predict/index', {
        type: this.forecastType,
        rankType
      }).then(res => {
        this.setMasterList(res);
      });
    },

    setMasterList(list) {
      this.masterList = list.map((e, i) => {
        let obj = {
          id: e.userId,
          name: e.nickName || e.userId,
          face: e.avatarUrl,
          forecast: e.predict && e.no ? `第${e.no}期最新预测` : '',
          count: e.count,
          continuous: e.continuous,
          record: e.record || 0,
          ranking: i + 1,
          rateOfReturn: e.rate || 0,
          forecastType: this.forecastType
          // TODO 分享相关
          // sharePrice: 20,
          // shareSubsidy: 5.5
        };
        if (i < 3) obj.rankingImg = this[`masterRankingImg${i + 1}`];
        return obj;
      });
    },

    setSidbarScrollTop(i) {
      let container = document.querySelector('.js-master-sidebar');
      let containerH = container.offsetHeight;
      let itemList = document.querySelectorAll('.js-sidebar-item');
      let scrollTopNum =
        itemList[i].offsetTop - containerH / 2 + itemList[i].offsetHeight / 2;
      container.scrollTop = scrollTopNum;
    },

    handleSidbarChange(i) {
      this.curFilter = 1;
      this.setSidbarScrollTop(i);
      document.querySelector('.js-master-list').scrollTop = 0;
      // 请求新数据
      this.getMasterList();
    },

    // TODO 页面分享
    // initWxShare() {
    //   console.log('initWxShare');
    // },

    handleFilterClick(val) {
      if (this.curFilter === val) return;
      this.getMasterList(val === 1 ? 'rank' : 'callback');
      this.curFilter = val;
    }
  },
  beforeRouteEnter(to, from, next) {
    if (from.name && (from.name === 'master' || from.name === 'serviceList')) {
      to.meta.isBack = true;
    }
    next(vm => {
      if (to.meta.isBack) {
        vm.$nextTick(() => {
          document.querySelector('.js-master-list').scrollTop =
            to.meta.scollTopPosition;
        });
      }
    });
  },
  beforeRouteLeave(to, from, next) {
    from.meta.scollTopPosition =
      document.querySelector('.js-master-list').scrollTop;
    next();
  },
  async activated() {
    // TODO 页面分享
    // this.initWxShare();
    if (!this.$route.meta.isBack) {
      let idx = this.sidebarList.indexOf(
        Number(this.$route.query.forecastType) || 10
      );
      this.curSidebarIdx = idx;
      this.handleSidbarChange(this.curSidebarIdx);
    }
    this.setSidbarScrollTop(this.curSidebarIdx);
    this.$route.meta.isBack = false;
  }
};
</script>

<style lang="scss" scoped>
.ad {
  &-link {
    width: 100%;
    height: 210px;
  }

  &-img {
    width: 100%;
    height: 100%;
  }
}

.master {
  &-container {
    display: flex;
    padding-top: 30px;
    height: calc(100vh - 310px);
    overflow: hidden;
    background: #f5f5f5;

    .filter {
      @include align-center;
      justify-content: flex-end;
      z-index: 2;
      position: absolute;
      top: 0;
      right: 30px;
      width: 100%;
      height: 82px;
      background: #f5f5f5;

      &-item {
        @include align-center;
        margin-left: 60px;
        font-size: 30px;
        color: #919191;

        &_active {
          @extend .filter-item;
          color: #e12c00;
        }
      }

      &-icon {
        margin-right: 14px;
        color: #919191;

        &_active {
          @extend .filter-icon;
          color: #e12c00;
        }
      }
    }
  }

  &-sidebar {
    z-index: 2;
    padding: 0 0 0;
    width: 160px;
    background: #f5f5f5;

    &::-webkit-scrollbar {
      display: none;
    }

    &::v-deep {
      .van-sidebar-item {
        font-size: 34px;
        font-weight: 500;
        background: #fff;

        &--select {
          background: #f5f5f5;
          color: #ff5225;

          &::before {
            background: #ff5225;
          }
        }
      }
    }
  }

  &-list {
    padding: 0 30px 60px;
    width: 590px;
    overflow: auto;
    background: #f5f5f5;

    &_hasPaddingTop {
      @extend .master-list;
      padding-top: 62px;
    }
  }

  &-item {
    margin-bottom: 30px;

    &:last-of-type {
      margin-bottom: 0;
    }
  }
}
</style>
