<template>
  <router-link
    to="/user/serviceList"
    class="module-serviceBanner"
    v-show="show"
  >
    <div @click.stop.prevent="hideBanner">
      <app-icon class="service-banner_close" name="close" size="40"></app-icon>
    </div>
  </router-link>
</template>

<script>
export default {
  data() {
    return {
      show: true
    };
  },
  methods: {
    hideBanner() {
      this.show = false;
    },

    showBanner() {
      this.show = true;
    }
  }
};
</script>

<style lang="scss" scoped>
.module-serviceBanner {
  @include align-center;
  justify-content: flex-end;
  padding: 0 30px;
  width: 100vw;
  height: 108px;
  background: rgba(255, 221, 181, 0.95)
    url(~image/user/service/service-banner.png) 0 0 / cover no-repeat;

  .close {
    z-index: 2;
  }
}
</style>
