<template>
  <van-popup class="coupon-popup" :value="show">
    <div class="coupon-wrap" v-if="couponData.empty">
      <img
        class="coupon-img"
        src="~image/index/coupon/coupon.png"
        @click="handleCouponImgClick"
      />
      <app-icon
        class="coupon-close"
        name="close"
        size="54"
        @click="$emit('update:show', false)"
      ></app-icon>
    </div>
    <div class="success-wrap" v-if="!couponData.empty && couponData.message">
      <img class="success-img" src="~image/index/coupon/success.png" alt="" />
      <p>您已领取3.99元新用户补贴<br />可在个人中心查看</p>
      <div class="success-btn" @click="$emit('update:show', false)">好的</div>
    </div>
  </van-popup>
</template>

<script>
import { mapState, mapMutations } from 'vuex';

export default {
  props: {
    show: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {};
  },
  computed: {
    ...mapState({
      couponData: state => state.user.couponData,
      userInfo: state => state.user.userInfo
    })
  },
  methods: {
    ...mapMutations({ updateCouponData: 'user/setCouponData' }),
    handleLinkClick() {
      this.$router.push('/login');
    },

    handleCouponImgClick() {
      if (!this.userInfo.id) return this.$router.push('/login');
      this.$http('/user/receiveCoupon').then(() => {
        this.updateCouponData({ couponEmpty: false, msgNewUser: '领取成功' });
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.coupon-popup {
  overflow: initial;
}

.coupon {
  &-popup {
    background: transparent;
  }

  &-wrap {
    @include align-center;
    flex-direction: column;
  }

  &-img {
    width: 494px;
    height: 578px;
  }

  &-close {
    margin-top: 20px;
  }
}

.success {
  &-wrap {
    padding: 105px 33px 41px;
    width: 542px;
    height: 408px;
    line-height: 54px;
    border-radius: 16px;
    text-align: center;
    font-size: 36px;
    color: #232323;
    background: #fff;
  }

  &-img {
    z-index: 2;
    position: absolute;
    top: -160px;
    left: calc(50% - 112px);
    width: 224px;
    height: 200px;
  }

  &-btn {
    @include flex-center-center;
    margin: 64px auto 0;
    width: 326px;
    height: 98px;
    border-radius: 49px;
    font-size: 34px;
    font-weight: 500;
    color: #fff;
    background: linear-gradient(180deg, #fb9d43 0%, #ff3f21 100%);
    box-shadow: 0px 5px 18px 0px rgba(255, 40, 0, 0.28);
  }
}
</style>
