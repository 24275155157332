<template>
  <div class="distribution" v-if="distributionData">
    <slot name="title"></slot>
    <div class="distribution-datas">
      <div class="distribution-data">
        <p class="distribution-data_name">分销商(个)</p>
        <p class="distribution-data_text">
          {{ distributionData.distributionNumber }}
        </p>
      </div>
      <div class="distribution-data">
        <p class="distribution-data_name">订单(笔)</p>
        <p class="distribution-data_text">{{ distributionData.orderTotal }}</p>
      </div>
      <div class="distribution-data">
        <p class="distribution-data_name">累计利润(元)</p>
        <p class="distribution-data_text">{{ distributionData.profit }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  computed: {
    ...mapState({ distributionData: state => state.user.distributionData })
  }
};
</script>

<style lang="scss" scoped>
.distribution {
  padding: 40px 30px;
  border-radius: 16px;
  background: #fff;
  box-shadow: 0px 0px 28px 0px rgba(153, 153, 153, 0.22);

  &-datas {
    @include align-center;
    margin-top: 43px;
  }

  &-data {
    flex: 1;
    text-align: center;

    &:not(:last-of-type) {
      @include hairlines(right, #e1e1e1);
    }

    &_name {
      margin-bottom: 17px;
      color: #999;
    }

    &_text {
      font-size: 36px;
    }
  }
}
</style>
