<template>
  <!-- 首页 -->
  <div v-if="initialized" class="page-home">
    <div class="banner-video" v-if="bannerVideoShow">
      <module-video></module-video>
    </div>
    <van-swipe v-else class="banner" :autoplay="3000" indicator-color="#fff">
      <van-swipe-item>
        <router-link to="/user/serviceList" class="banner-item">
          <img class="item-img" src="~image/index/banner1.png" /></router-link
      ></van-swipe-item>
      <van-swipe-item>
        <router-link to="/user/servicePage" class="banner-item">
          <img class="item-img" src="~image/index/banner2.png" /></router-link
      ></van-swipe-item>
    </van-swipe>
    <module-result ref="result"></module-result>
    <module-luck class="luck" :luckData="luckData"></module-luck>
    <div class="master-list">
      <div class="list-title">
        <router-link to="/masterList" class="list-link">更多大师</router-link>
      </div>
      <div class="list-container">
        <router-link
          class="master-item"
          v-for="item in masterList"
          :key="item.id"
          :to="`/lottery/master?masterId=${item.id}&forecastType=10`"
        >
          <master-item :masterData="item"></master-item>
        </router-link>
      </div>
    </div>
    <p class="tip">
      访问本站表示您同意：<br />本站提供的资料和数据仅供参考，请您在使用前仔细甄别并慎重对待，使用本站数据产生的任何后果，本站不承担任何责任。
    </p>
    <service-banner class="service-banner" ref="serviceBanner"></service-banner>
    <coupon-popup :show.sync="couponShow"></coupon-popup>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex';
import masterItem from '@/components/masterItem';
import serviceBanner from '@/components/serviceBanner.vue';
import moduleResult from './components/moduleResult.vue';
import moduleLuck from './components/moduleLuck.vue';
import couponPopup from './components/moduleCouponPopup.vue';
import moduleVideo from './components/moduleVideo.vue';

export default {
  components: {
    moduleResult,
    moduleLuck,
    masterItem,
    couponPopup,
    serviceBanner,
    moduleVideo
  },
  data() {
    return {
      initialized: false,
      luckData: null,
      masterList: [],
      couponShow: false
    };
  },
  computed: {
    ...mapState({
      couponData: state => state.user.couponData,
      restDateList: state => state.result.restDateList,
      curTime: state => state.result.curTime
    }),

    bannerVideoShow() {
      let { restDateList: list } = this;
      if (!this.curTime || !list) return false;
      let day = this.$dayjs(this.curTime);
      if (list.includes(day.format('YYYY-MM-DD'))) return false;
      let start = this.$dayjs(day.format('YYYY-M-D')).add(20, 'h').add(25, 'm');
      let end = this.$dayjs(day.format('YYYY-M-D')).add(20, 'h').add(50, 'm');
      if (!day.isBetween(start, end)) return false;
      return true;
    }
  },
  watch: {
    couponData: {
      immediate: true,
      deep: true,
      handler(data) {
        if (!data.empty && !data.message) return;
        if (data.empty || (!data.empty && data.message)) this.couponShow = true;
      }
    },
    initialized() {
      this.$nextTick(() => {
        this.$refs.result.getResultData();
      });
    }
  },
  methods: {
    ...mapMutations({ setResultData: 'result/setResultData' }),

    ...mapActions({ getRestDateList: 'result/getRestDateList' }),

    initialization() {
      return this.$http('index', {}, { dialog: false }).then(res => {
        this.initialized = true;
        this.setResultData(res.dateNumber);
        this.setLuckData(res.lucky);
        this.setMasterList(res.checkList);
      });
    },

    getMastertData(config = {}) {
      return this.$http('masterListData', {}, config).then(res => {
        this.setMasterList(res);
      });
    },

    setMasterList(data) {
      this.masterList = data.map(e => {
        return {
          id: e.userId,
          name: e.nickName || `用户${e.userId}`,
          face: e.avatarUrl,
          count: e.count,
          continuous: e.continuous
        };
      });
    },

    setLuckData(data) {
      this.luckData = {
        id: data.userId,
        name: data.nickName || '',
        face: data.avatarUrl || ''
      };
    }
  },
  beforeRouteEnter(to, from, next) {
    if (from.name && from.name !== 'index') {
      to.meta.isBack = true;
    }
    next();
  },
  async activated() {
    this.getRestDateList();
    await this.initialization();
    if (this.$refs.result) {
      this.$refs.result.getResultData();
    }
  },
  deactivated() {
    if (this.$refs.result) {
      this.$refs.result.clearResultDataUpdate();
    }
    if (this.$refs.serviceBanner) {
      this.$refs.serviceBanner.showBanner();
    }
  }
};
</script>

<style lang="scss" scoped>
.page-home {
  background: #bc0026;
}

.banner {
  width: 100vw;
  height: 280px;

  &-item {
    width: 100%;
    height: 100%;

    .item-img {
      width: 100%;
      height: 100%;
    }
  }
}

.luck {
  margin: 15px 0 0;
}

.master {
  &-list {
    padding-bottom: 30px;

    .list {
      &-title {
        z-index: 2;
        padding: 17px 30px 0;
        height: 92px;
        text-align: right;
        background: url(~image/index/list-title.png) center 0 / contain
          no-repeat;
      }

      &-link {
        font-size: 32px;
        color: #fff;
      }

      &-container {
        margin-top: -22px;
      }
    }
  }

  &-item {
    margin: 30px auto 0;
    width: 690px;
    border-radius: 16px;
    background: linear-gradient(90deg, #ffffff 0%, #fff2e7 100%);

    &:first-of-type {
      margin-top: 0;
    }
  }
}

.tip {
  padding: 0 30px 130px;
  text-align: center;
  color: #fff;
}

.service-banner {
  z-index: 4;
  position: fixed;
  bottom: calc($tabBarHeight + constant(safe-area-inset-bottom));
  bottom: calc($tabBarHeight + env(safe-area-inset-bottom));
  left: 0;
}
</style>
