<template>
  <!-- 个人中心 -->
  <div class="user">
    <module-user-info class="wrap"></module-user-info>
    <master-list class="wrap" :list="masterList" v-if="masterList.length">
      <template v-slot:title>
        <p class="title">
          <span class="title-text">我的关注</span>
          <router-link class="title-link" to="/user/followerList"
            >查看更多</router-link
          >
        </p>
      </template>
    </master-list>
    <router-link to="/user/serviceList" class="ad-link_1"></router-link>
    <module-promote-data class="wrap" v-if="userInfo.isSalesman">
      <template v-slot:title>
        <p class="title">
          <span class="title-text">我的直推数据</span>
          <router-link class="title-link" to="/distribution/promoteData"
            >查看详细数据</router-link
          >
        </p>
      </template>
    </module-promote-data>
    <router-link
      v-if="userInfo.isSalesman || !distributionApplyData"
      :to="
        userInfo.isSalesman
          ? '/distribution/distributionShare'
          : '/user/register'
      "
      :class="userInfo.isSalesman ? 'ad-link_2' : 'ad-link_3'"
    ></router-link>
    <module-distribution-data class="wrap" v-if="userInfo.isSalesman">
      <template v-slot:title>
        <p class="title">
          <span class="title-text">分销数据</span>
          <router-link class="title-link" to="/distribution/distributionData"
            >查看详细数据</router-link
          >
        </p>
      </template>
    </module-distribution-data>
    <div v-else-if="distributionApplyData" class="wrap distribution-status">
      <p v-if="!distributionApplyData.status">分销商申请审核中</p>
      <template v-else-if="distributionApplyData.status === 2">
        <p>
          分销商申请未能通过<br />拒绝原因：{{ distributionApplyData.error }}
        </p>
        <router-link to="/user/register" class="distribution-status_link"
          >点我重新申请</router-link
        >
      </template>
    </div>
    <p class="tip">
      访问本站表示您同意：<br />本站提供的资料和数据仅供参考，请您在使用前仔细甄别并慎重对待，使用本站数据产生的任何后果，本站不承担任何责任。
    </p>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex';
import moduleUserInfo from './components/moduleUserInfo.vue';
import moduleDistributionData from './components/moduleDistributionData.vue';
import modulePromoteData from './components/modulePromoteData.vue';
import masterList from './components/masteList.vue';

export default {
  components: {
    moduleUserInfo,
    moduleDistributionData,
    modulePromoteData,
    masterList
  },
  data() {
    return {
      initializing: false,
      masterList: []
    };
  },
  computed: {
    ...mapState({
      userInfo: state => state.user.userInfo,
      distributionApplyData: state => state.user.distributionApplyData
    })
  },
  methods: {
    ...mapActions('user', [
      'getUserInfo',
      'getPromoteData',
      'getDistributionData'
    ]),

    ...mapMutations('user', [
      'setUserInfo',
      'setPromoteData',
      'setDistributionData',
      'setDistributionApplyData',
      'setSuperVipData'
    ]),

    initialization(showLoading) {
      this.initializing = true;
      if (showLoading) this.$toast.loading({});
      Promise.all([
        this.getUserInfo({ loading: showLoading, dialog: false }),
        this.getMasterList({ loading: showLoading, dialog: false })
      ])
        .catch(err => {
          let errData = JSON.parse(err.message);
          if (!showLoading) {
            this.$dialog.alert({
              message: `页面加载失败，请联系管理员！\n错误信息：${
                errData.message || '未知错误'
              }`
            });
          }
        })
        .finally(() => {
          if (showLoading) this.$toast.clear();
          this.initializing = false;
        });
    },

    getUserInfo(config = {}) {
      return this.$http('user/center', {}, config).then(res => {
        this.setUserInfo({ ...res.user, parentCode: res.parentCode });
        if (res.push || res.push.length === 0) {
          this.setPromoteData(res.push);
        }
        if (res.superior || res.superior.length === 0) {
          this.setDistributionData(res.superior);
        }
        this.setDistributionApplyData(res.superiorApply || null);
        this.setSuperVipData(res.user.vipData || null);
      });
    },

    getMasterList(config) {
      return this.$http(
        'followers/index',
        { page: 1, pageSize: 4 },
        config
      ).then(res => {
        this.masterList = res.data.map(e => {
          return {
            id: e.followingId,
            name: e.nickName,
            face: e.avatarUrl,
            type: e.type,
            new: e.predict
          };
        });
      });
    }
  },
  beforeRouteEnter(to, from, next) {
    if (from.name && from.name !== 'user') {
      to.meta.isBack = true;
    }
    next();
  },
  activated() {
    // this.$dialog.alert({ title: '链接', message: window.location.href });
    if (this.initializing || !localStorage.getItem('token')) return;
    let showLoading = !this.$route.meta.isBack;
    this.initialization(showLoading);
  }
};
</script>

<style lang="scss" scoped>
.user {
  padding: 0 30px 166px;
  min-height: calc(100vh - $tabBarHeight);
  background: url(~image/user/user/body-bg.png) left top / contain no-repeat;
}

.wrap {
  margin: 0 auto;
  width: 100%;

  &:last-of-type {
    margin-bottom: 0;
  }
}

.title {
  @include flex-between-center;

  .title {
    &-text {
      font-size: 36px;
      font-weight: 500;
    }

    &-link {
      @include align-center;
      @include arrow(13px, right, #ff825e);

      font-weight: 26px;
      color: #ff825e;

      &::after {
        margin-left: 7px;
      }
    }
  }
}

.module-masterList {
  margin-top: 30px;
}

.ad {
  &-link {
    @extend .wrap;
    width: 690px;

    &_1 {
      @extend .ad-link;
      margin: 28px auto 0;
      height: 142px;
      background: url(~image/user/user/ad1.png) center bottom/contain no-repeat;
    }

    &_2 {
      @extend .ad-link;
      margin: 20px auto 0;
      height: 137px;
      background: url(~image/user/user/ad2.png) center bottom/contain no-repeat;
    }

    &_3 {
      @extend .ad-link;
      margin: 20px auto 0;
      height: 137px;
      background: url(~image/user/user/ad3.png) center bottom/contain no-repeat;
    }
  }
}

.promote {
  margin: -20px auto 0;
}

.distribution {
  margin: 30px auto 0;

  &-status {
    @include flex-center-center;
    flex-direction: column;
    z-index: 2;
    margin: -20px auto 0;
    width: 690px;
    min-height: 200px;
    border-radius: 16px;
    font-size: 30px;
    font-weight: 500;
    text-align: center;
    background: #fff;
    box-shadow: 0px 0px 28px 0px rgba(153, 153, 153, 0.22);

    &_link {
      margin-top: 16px;
      font-size: 28px;
      color: #ff825e;
    }
  }
}

.tip {
  position: absolute;
  left: 0;
  bottom: 0;
  padding: 0 30px 30px;
  text-align: center;
  color: #ccc;
}
</style>
