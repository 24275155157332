<template>
  <router-link
    :to="`/lottery/master?masterId=${luckData.id}&forecastType=10`"
    class="module-luck"
    v-if="luckData"
  >
    <img v-if="luckData.face" class="master-face" :src="luckData.face" />
    <app-icon v-else class="master-face" name="touxiang"></app-icon>
    <p class="master-name">{{ luckData.name }}</p>
  </router-link>
</template>

<script>
export default {
  props: {
    luckData: {
      type: Object,
      default() {
        return null;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.module-luck {
  width: 100vw;
  height: 414px;
  background: url(~image/index/luck-bg.png) 0 0 / cover no-repeat;
}

.master {
  &-face {
    position: absolute;
    top: 161px;
    left: 50%;
    width: 120px;
    height: 120px;
    border-radius: 50%;
    box-shadow: 0px 4px 12px 0px rgba(122, 0, 13, 0.55);
    background: #ccc;
    transform: translateX(-50%);
  }

  &-name {
    @include text-ellipsis;
    position: absolute;
    top: 296px;
    left: 50%;
    max-width: 250px;
    font-size: 42px;
    font-weight: 500;
    color: #7a000d;
    transform: translateX(-50%);
  }
}
</style>
