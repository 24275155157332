<template>
  <div class="master" v-if="masterData">
    <p class="master-ranking">
      <img
        v-if="masterData.rankingImg"
        class="ranking-img"
        :src="masterData.rankingImg"
      />
      <span v-else>{{ masterData.ranking }}</span>
    </p>
    <div class="master-container">
      <div class="master-info">
        <app-icon
          class="master-face"
          name="touxiang"
          size="88"
          v-show="!masterData.face"
        ></app-icon>
        <img
          class="master-face"
          v-show="masterData.face"
          :src="masterData.face"
          alt="码师头像"
        />
        <div class="info-wrap">
          <p class="info-item_name">{{ masterData.name }}</p>
          <p class="info-item">
            {{ masterData.forecast || '未发布最新预测' }}
          </p>
        </div>
      </div>
      <div class="master-count">
        <div
          class="count-wrap"
          v-if="masterData.count && masterData.continuous"
        >
          <p class="count-item">
            <span class="count-text">10</span>
            <span>中</span>
            <span class="count-text">{{ masterData.count }}</span>
          </p>
          <p class="count-item">
            <span>连中</span>
            <span class="count-text">{{ masterData.continuous }}</span>
          </p>
        </div>
        <p class="master-rateOfReturn" v-if="masterData.forecastType === 10">
          回报率: {{ masterData.rateOfReturn }}%
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    masterData: {
      type: Object,
      default() {
        return null;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.master {
  position: relative;
  display: flex;
  padding: 30px 20px;
  border-radius: 16px;
  background: #fff;
  box-shadow: 0px 0px 28px 0px rgba(153, 153, 153, 0.22);

  &-ranking {
    @include flex-center-center;
    margin: 23px 10px 0 0;
    width: 46px;
    height: 46px;
    font-size: 28px;
    font-weight: 500;
    color: #bababa;

    .ranking {
      &-img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }

  &-container {
    flex: 1;
  }

  &-face {
    flex: 0 0 auto;
    margin: 0 30px 0 11px;
    width: 88px;
    height: 88px;
    border-radius: 50%;
  }

  &-info {
    @include align-center;
    font-size: 28px;
    color: #7d7d7d;

    .info {
      &-item {
        margin-bottom: 6px;

        &:last-of-type {
          margin-bottom: 0;
        }

        &_name {
          @include text-ellipsis;
          max-width: 282px;
          font-size: 36px;
          font-weight: 500;
          color: #333;
        }
      }
    }
  }

  &-name {
    @include text-ellipsis;
    margin-bottom: 16px;
    padding-right: 30px;
    font-size: 32px;
    font-weight: 500;
  }

  &-count {
    @include flex-between-center;
    display: flex;
    margin-top: 12px;

    .count {
      &-wrap {
        @include align-center;
      }

      &-item {
        @include align-center;
        margin-right: 20px;
        padding: 0 0 0 10px;
        height: 48px;
        border-radius: 8px;
        font-size: 28px;
        color: #7d7d7d;
        background: linear-gradient(90deg, #fff2dc 0%, #ffffff 100%);

        &:last-of-type {
          margin-right: 0;
        }
      }

      &-text {
        color: #e12c00;
      }
    }
  }

  &-rateOfReturn {
    font-size: 28px;
    color: #e12c00;
  }
}
</style>
